<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')">
              New Ticket
            </b-button>
          </div>
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
            <!-- Filters -->
            <h6 class="section-label mt-1 mb-1 px-2">Status</h6>

            <b-list-group class="list-group-messages">
              <b-list-group-item v-for="filter in status" :key="filter.title + $route.path" :to="statusToWithActiveQuery(filter.route)"
                                 :active="isActiveRoute(filter.route)" @click="$emit('close-left-sidebar')">
                <span class="bullet bullet-sm mr-1" :class="`bullet-${filter.color}`"/>
                <span class="text-capitalize">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <h6 class="section-label mt-3 mb-1 px-2">
              Labels
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item v-for="label in filteredLabels" :key="label.title + $route.path" :to="label.route"
                                :active="hasActiveLabel(label.route)" class="d-flex justify-content-between align-items-center"
                                @click="$emit('close-left-sidebar')">
                <div>
                  <font-awesome-icon v-if="label.icon.startsWith('fa')" :icon="label.icon" :class="['mr-75']" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                  <feather-icon v-else :icon="label.icon" size="18" class="mr-75"/>
                  <span class="text-capitalize line-height-1">{{ label.title }}</span>
                </div>
                <b-button size="sm" variant="transparent" :to="{ query: { }}" class="py-0">
                  <feather-icon v-if="hasActiveLabel(label.route)" icon="XIcon" size="14" />
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import support from '@/mixins/support.mixin';
import router from '@/router';

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
  },
  mixins: [ support ],
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isActiveRoute(route) {
      const { route: resolvedRoute } = this.$router.resolve(route)
      return resolvedRoute.path === this.$router.currentRoute.path
             || (this.$router.currentRoute.params.status === 'all' && resolvedRoute.name === 'support-tickets')
    },
    hasActiveLabel(route) {
      const { route: resolvedRoute } = this.$router.resolve(route)
      if(!this.$router.currentRoute.query.label) return false
      return resolvedRoute.query.label === this.$router.currentRoute.query.label
    },
    statusToWithActiveQuery(route) {
      if(this.$route.query) {
        route.query = this.$route.query
      }
      return route
    },
    isActiveStatusRoute(route) {
      const { route: resolvedRoute } = this.$router.resolve(route)
      return resolvedRoute?.params?.status === this.$router.currentRoute?.params?.status
    },
    isActiveLabelRoute(route) {
      const { route: resolvedRoute } = this.$router.resolve(route)
      return resolvedRoute.name === this.$router.currentRoute.name
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
