<template>
  <b-modal
    id="compose-mail"
    :visible="show"
    title="Create Ticket"
    :modal-class="options.expanded ? 'modal-sticky-full' : 'modal-sticky'"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="xl"
    no-fade
    hide-backdrop
    static
    no-close-on-backdrop
    @change="(val) => $emit('show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">Create Ticket</h5>
      <div class="modal-actions">
        <feather-icon icon="MinusIcon" class="cursor-pointer" @click="$emit('show-modal', false)"/>
        <feather-icon icon="Maximize2Icon" class="ml-1 cursor-pointer" @click="options.expanded = !options.expanded"/>
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('show-modal', false)"/>
      </div>
    </template>

    <!-- Modal: Body -->
    <template #default>
      <validation-observer ref="observer" v-slot="{ handleSubmit, errors, failed }" tag="div">

        <b-alert :show="failed" variant="danger" class="mb-0 square d-flex">
          <div v-for="(error, key) in errors" :key="key">
            <b-badge v-if="error.length"  variant="danger" class="mr-25">
              {{ error[0] }}
            </b-badge>
          </div>
        </b-alert>

        <b-form @submit.stop.prevent="handleSubmit(create)">
          <!-- Title -->
          <validation-provider v-slot="validationContext" vid="title" name="Title" rules="required|min:5">
            <div class="compose-mail-form-field">
              <label for="title">Title: </label>
              <b-form-input id="title" v-model="ticket.title" :state="getValidationState(validationContext)"/>
            </div>
          </validation-provider>

          <!-- Labels -->
          <div class="compose-mail-form-field">
            <label for="labels">Labels: </label>
            <v-select id="labels" v-model="ticket.labels" :options="filteredLabels" label="title" :reduce="label => label.title" multiple class="v-select-inline">
              <template #option="{ title, icon }">
                <font-awesome-icon v-if="icon.startsWith('fa')" :icon="icon" class="mr-50 align-middle" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                <feather-icon v-else :icon="icon" size="18" class="mr-50 align-middle"/>
                <span class="align-middle text-capitalize">{{ title }}</span>
              </template>
              <template #selected-option="{ title, icon }">
                <font-awesome-icon v-if="icon.startsWith('fa')" :icon="icon" class="mr-25 align-middle" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                <feather-icon v-else :icon="icon" size="15" class="mr-25 align-middle"/>
                <span class="align-middle text-capitalize">{{ title }}</span>
              </template>
            </v-select>
          </div>

          <!-- Text -->
          <validation-provider vid="text" name="Message" rules="required|min:50">
            <tip-tap v-model="ticket.text"
                     :toolbar-options="{ alignment: false, table: false, image: false, schools: true }"
                     toolbar-primary-options-class="pr-1"
                     placeholder="New Ticket"
            />
          </validation-provider>
        </b-form>
      </validation-observer>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" class="mr-1"
                  @click="$refs.observer.handleSubmit(create)">
          Create
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="clear">
          Clear
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import {uuid} from 'vue-uuid';
import {API, graphqlOperation} from 'aws-amplify';
import {createTicket, listSchools} from './queries/ticket-modal';
import notify from '@/mixins/notify.mixin';
import support from '@/mixins/support.mixin';
import Fuse from 'fuse.js';
import TipTap from '@/components/TipTap.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    TipTap,
    vSelect,
  },
  mixins: [support, notify ],
  model: {
    prop: 'show',
    event: 'show-modal',
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      ticket: {
        title: this.data ? this.data.title : null,
        text: this.data ? this.data.text : null,
        labels: this.data ? this.data.labels : [],
        status: 'open',
      },
      lastIndex: 0,
      options: {
        editor: {
          modules: {
            toolbar: '#quill-toolbar',
          },
          placeholder: 'Message',
          lastIndex: 0
        },
        expanded: false,
        schools: {
          search: '',
          load: false,
          loading: true,
          items: [
            { name: { legal: 'N', popular: 'n-no'} },
            { name: { legal: 'Y', popular: 'y-yes'} },
          ]
        }
      }
    }
  },
  computed: {
    editor() {
      return this.$refs['new-ticket-editor'].quill
    },
    schools() {
      if(this.options.schools.load && this.options.schools.loading) {
        this.listSchools()
      }
      if(this.options.schools.loading) {
        return []
      }
      const fuse = new Fuse(this.options.schools.items, {
        keys: ['name.legal', 'name.popular'],
        threshold: 0.2,
        shouldSort: true,
      })
      return this.options.schools.search.length
          ? fuse.search(this.options.schools.search, { limit: 5 }).map(({ item }) => item)
          : []
    }
  },
  watch: {
    'options.expanded'(value) {
      this.$nextTick(() => {
        const modal = document.querySelector('.modal-content')

        if (value) {
          const container = document.querySelector('.email-app-list');
          modal.style.width = `${container.offsetWidth}px`
          modal.style.height = `${container.offsetHeight}px`
          modal.style.boxShadow = 'none'
        }
        else {
          modal.style.removeProperty('width')
          modal.style.removeProperty('height')
          modal.style.removeProperty('box-shadow')
        }
      })
    }
  },
  methods: {
    async create() {
      try {
        const id = uuid.v4();
        const input = {
          id: id,
          title: this.ticket.title,
          text: this.ticket.text,
          labels: this.ticket.labels,
          userID: this.currentUser.id,
          status: 'open',
          state: {
            locked: false,
            hidden: false
          }
        }

        const response = await API.graphql(graphqlOperation(createTicket, { input: input }));
        const ticket = response.data.createTicket
        this.$emit('created', ticket)
        this.clear()
        this.$root.$emit('bv::toggle::modal', 'compose-mail')
        await this.notify({ title: 'Success', text: 'Ticket was successfully created', icon: 'fas fa-server', variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Ticket failed to create', icon: 'fas fa-server', variant: 'danger'});
      }
    },
    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true
      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
      schools.push(...response.data.listSchools.items);

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools
        this.options.schools.loading = false
      }
    },
    clear() {
      this.$refs.observer.reset()
      this.ticket = {
        title: null,
        text: null,
        labels: [],
        status: 'open',
      }
    },

    onChange(event) {
      this.options.editor.lastIndex = this.editor.getSelection()?.index;
    },
    addSchoolLink(option) {
      const resolved = this.$router.resolve({ name: 'management-school', params: { slug: option.slug } })
      this.editor.insertText(this.options.editor.lastIndex, option.name.legal, { link: resolved.href })
    }
  },

  setup(_, { emit }) {
    const composeData = ref({})
    const showCcField = ref(false)
    const showBccField = ref(false)


    /* eslint-disable global-require */
    const emailToOptions = [
      { avatar: null, name: 'Jane Foster' },
      { avatar: null, name: 'Donna Frank' },
      { avatar: null, name: 'Gabrielle Robertson' },
      { avatar: null, name: 'Lori Spears' },
      { avatar: null, name: 'Sandy Vega' },
      { avatar: null, name: 'Cheryl May' },
    ]
    /* eslint-enable global-require */

    const sendEmail = () => {
      composeData.value = {}
      emit('update:shall-show-email-compose-modal', false)
    }

    const discardEmail = () => {
      composeData.value = {}
      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,
      emailToOptions,
      showCcField,
      showBccField,

      // Email actions
      sendEmail,
      discardEmail,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped lang="scss">
::v-deep {
  .tiptap-toolbar-options {
    padding-right: 1rem
  }
}

</style>

<style lang="scss">

.modal.modal-sticky-full {
  top: 4.754rem;
  left: auto;
  height: auto;
  #width: 75%!important;
  max-width: calc(100vw - (100vw - 100%) - 420px)
}

@media (min-width: 1200px) {
  .modal.modal-sticky-full .modal-xl {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .modal.modal-sticky-full .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

.message-editor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.v-select-inline {
  display: inline-block;
  width: 98%;
}

.v-select-inline .vs__dropdown-toggle {
  border: 0;
}

.v-select-inline.vs--open .vs__dropdown-toggle {
  box-shadow: unset!important;
}

.v-select-inline .vs__selected {
  background-color: rgba(33, 41, 105, 0.12);
  color: #212969;
}

form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }
}
</style>
