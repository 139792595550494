// eslint-disable-next-line import/prefer-default-export
export const createTicket = /* GraphQL */ `
    mutation CreateTicket(
        $input: CreateTicketInput!
        $condition: ModelTicketConditionInput
    ) {
        createTicket(input: $input, condition: $condition) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            replies {
                items {
                    id
                }
                nextToken
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name {
                    legal
                    popular
                }
                state {
                    enabled
                }
                districtSchoolsId
            }
            nextToken
        }
    }
`;


