// eslint-disable-next-line import/prefer-default-export
export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            schools {
                items {
                    id
                    school {
                        id
                        name {
                            legal
                            popular
                        }
                        district {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                    }
                }
            }
            ensembleIds
            instrumentIds
        }
    }
`;

export const getTicket = /* GraphQL */ `
    query GetTicket($id: ID!) {
        getTicket(id: $id) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const listTickets = /* GraphQL */ `
    query ListTickets(
        $id: ID
        $filter: ModelTicketFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listTickets(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                title
                text
                status
                labels
                userID
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        name
                        size
                        src
                    }
                }
                state {
                    locked
                    hidden
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listTicketsByStatus = /* GraphQL */ `
    query ListTicketsByStatus(
        $status: String!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTicketFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listTicketsByStatus(
            status: $status
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                title
                text
                status
                labels
                userID
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        name
                        size
                        src
                    }
                }
                state {
                    locked
                    hidden
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listTicketsByUser = /* GraphQL */ `
    query ListTicketsByUser(
        $userID: ID!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTicketFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listTicketsByUser(
            userID: $userID
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                title
                text
                status
                labels
                userID
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        name
                        size
                        src
                    }
                }
                state {
                    locked
                    hidden
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listTicketReplies = /* GraphQL */ `
    query ListTicketReplies(
        $id: ID
        $filter: ModelTicketReplyFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listTicketReplies(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                text
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        size
                        name
                        src
                    }
                }
                state {
                    locked
                    hidden
                }
                createdAt
                updatedAt
                ticketRepliesId
                ticketReplyUserId
            }
            nextToken
        }
    }
`;

export const onCreateTicket = /* GraphQL */ `
    subscription OnCreateTicket {
        onCreateTicket {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const onUpdateTicket = /* GraphQL */ `
    subscription OnUpdateTicket {
        onUpdateTicket {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const onDeleteTicket = /* GraphQL */ `
    subscription OnDeleteTicket {
        onDeleteTicket {
            id
            userID
        }
    }
`;


export const updateTicket = /* GraphQL */ `
    mutation UpdateTicket(
        $input: UpdateTicketInput!
        $condition: ModelTicketConditionInput
    ) {
        updateTicket(input: $input, condition: $condition) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteTicket = /* GraphQL */ `
    mutation DeleteTicket(
        $input: DeleteTicketInput!
        $condition: ModelTicketConditionInput
    ) {
        deleteTicket(input: $input, condition: $condition) {
            id
            userID
            replies {
                items {
                    id
                }
                nextToken
            }
        }
    }
`;
export const deleteTicketReply = /* GraphQL */ `
    mutation DeleteTicketReply(
        $input: DeleteTicketReplyInput!
        $condition: ModelTicketReplyConditionInput
    ) {
        deleteTicketReply(input: $input, condition: $condition) {
            id
        }
    }
`;


