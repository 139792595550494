<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">

    <b-alert :show="enabled === false" variant="danger" class="rounded-0 mb-0">
      This system is currently disabled, and only visible to those with the administrator privileges.
    </b-alert>

    <b-overlay :show="settings.showLeftSidebar" style="height: inherit" :opacity="0.5" variant="light-primary" @click="settings.showLeftSidebar = false">
      <template #overlay>
        Click to close menu.
      </template>
      <template #default>
        <!-- Tickets -->
        <div class="email-app-list">

          <!-- App Searchbar Header -->
          <div class="app-fixed-search d-flex align-items-center">

            <!-- Toggle -->
            <div class="sidebar-toggle d-block d-lg-none ml-1">
              <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="settings.showLeftSidebar = true"/>
            </div>

            <!-- Search -->
            <div class="d-flex align-content-center justify-content-between w-100">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-muted"/>
                </b-input-group-prepend>
                <b-form-input v-model="search" :debounce="250" placeholder="Search Tickets"/>
              </b-input-group>
            </div>
          </div>

          <!-- App Action Bar -->
          <can do="manage" on="support-tickets">
            <div v-if="settings.showActions" class="app-action">
              <div class="action-left">
                <b-form-checkbox :checked="hasCheckedTickets" :indeterminate="hasCheckedTicketsIndeterminate" @change="checkAllTickets">
                  Select All
                </b-form-checkbox>
              </div>
              <div v-show="tickets.selected.length" class="align-items-center" :class="{'d-flex': tickets.selected.length}">

                <!-- Update Checked Tickets Labels -->
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon icon="TagIcon" size="17" class="align-middle text-body"/>
                  </template>
                  <b-dropdown-item v-for="(label) in filteredLabels" :key="label.title" @click="updateCheckedTicketsLabels(label.title)">
                    <font-awesome-icon v-if="label.icon.startsWith('fa')" :icon="label.icon" :class="['mr-75']" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                    <feather-icon v-else :icon="label.icon" size="18" class="mr-75"/>
                    <span class="text-capitalize">{{ label.title }}</span>
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Update Checked Tickets Status -->
                <b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-1" right>
                  <template #button-content>
                    <feather-icon icon="FlagIcon" size="17" class="align-middle text-body"/>
                  </template>
                  <b-dropdown-item v-for="status in filteredStatuses" :key="status.title" @click="updateCheckedTicketsStatus(status.title)">
                    <span :class="`mr-50 bullet bullet-${status.color} bullet-sm`" />
                    <span class="text-capitalize">{{ status.title }}</span>
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Delete Checked Tickets & Replies -->
                <feather-icon icon="TrashIcon" size="17" class="cursor-pointer ml-1" @click="deleteCheckedTicketsAndReplies"/>

              </div>
            </div>
          </can>


          <!-- Tickets -->
          <vue-perfect-scrollbar :settings="settings.perfectScrollbar" class="email-user-list scroll-area">
            <b-overlay :show="tickets.loading" :opacity="1" variant="white">
              <template v-if="tickets.loading">
                <b-card></b-card>
              </template>
              <template v-if="!tickets.loading">
                <ul class="email-media-list">
                  <b-media v-for="ticket in filteredTickets" :key="ticket.id" tag="li" no-body @click="selectTicket(ticket)">
                    <b-media-aside class="media-left mr-1">
                      <b-avatar class="avatar" size="40" variant="primary" :src="ticket.user.avatar.src" />
                      <!-- Actions -->
                      <template v-if="settings.showActions">
                        <div class="user-action">
                          <b-form-checkbox :checked="tickets.selected.includes(ticket.id)" @change="checkTicket(ticket.id)" @click.native.stop/>
                        </div>
                      </template>
                    </b-media-aside>

                    <b-media-body>
                      <div class="mail-details">
                        <div class="mail-items">
                          <h5 class="mb-0">
                            {{ ticket.title }}
                          </h5>
                          <small class="text-truncate"> {{ ticket.user.name.first }} {{ ticket.user.name.last }}</small>
                        </div>
                        <div class="mail-meta-item text-right">
                          <div class="mail-date">
                            {{ formatDateToMonthShort(ticket.createdAt, { hour: 'numeric', minute: 'numeric', }) }}
                          </div>
                          <b-badge :variant="getStatusColor(ticket.status)" class="ticket-status text-capitalize">{{ ticket.status }}</b-badge>
                        </div>
                      </div>

                      <div class="mail-message">
                        <!-- eslint-disable vue/no-v-html -->
                        <!--                    <p class="text-truncate mb-0" v-html="ticket.text" />-->
                        <!-- eslint-enable -->

                        <p class="text-truncate mb-25">{{ truncateTicketText(ticket.text) }}</p>

                        <b-badge v-for="(label) in ticket.labels" :key="label" pill class="text-capitalize mr-50" :variant="`light-${getLabelColor(label)}`">
                          <font-awesome-icon v-if="getLabelIcon(label).startsWith('fa')" :icon="getLabelIcon(label)" class="mr-25 align-middle" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                          <feather-icon v-else :icon="getLabelIcon(label)" size="18" class="mr-25"/>
                          <span class="align-middle">{{ label }}</span>
                        </b-badge>
                      </div>
                    </b-media-body>
                  </b-media>
                </ul>
                <div class="no-results" :class="{'show': !filteredTickets.length}">
                  <h5>No Items Found</h5>
                </div>
              </template>
            </b-overlay>
          </vue-perfect-scrollbar>



          <!--      <div id="ticket-footer">
                  <b-row>
                  <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span v-if="tickets.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} entries</span>
                    <span v-else class="text-muted">Showing 0 of {{ pagination.of }} entries</span>
                  </b-col>

                  &lt;!&ndash; Pagination &ndash;&gt;
                  <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-if="!tickets.loading" v-model="tickets.paging.page"
                                  :total-rows="tickets.paging.total"
                                  :per-page="tickets.paging.size"
                                  first-number
                                  last-number
                                  class="mb-0 mt-1 mt-sm-0"
                                  prev-class="prev-item"
                                  next-class="next-item"
                                  @input="onPageChange"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                </div>-->


        </div>

        <!-- Ticket -->
        <support-ticket v-if="selectedTicket"
                        :ticket="selectedTicket"
                        :current-user="currentUser"
                        :class="{'show': settings.showTicket}"
                        @deselect="deselectTicket"
                        @delete-ticket-and-replies="deleteTicketAndReplies"
        />

        <!-- New Ticket Modal -->
        <ticket-modal v-if="currentUser" v-model="settings.modal.show" :data="settings.modal.data" :current-user="currentUser" />
      </template>
    </b-overlay>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <support-ticket-sidebar
        :shall-show-email-compose-modal.sync="settings.modal.show"
        :emails-meta="settings.sidebar"
        :class="{'show': settings.showLeftSidebar }"
        @close-left-sidebar="settings.showLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {isToday, useRouter} from '@core/utils/utils'
import SupportTicketSidebar from '@/views/support/tickets/SupportTicketSidebar.vue';
import SupportTicket from '@/views/support/tickets/SupportTicket.vue';
import TicketModal from '@/views/support/tickets/TicketModal.vue';
import Fuse from 'fuse.js';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  listTickets,
  updateTicket, deleteTicket, deleteTicketReply,
  onCreateTicket, onDeleteTicket, onUpdateTicket, listTicketsByStatus, listTicketsByUser
} from './queries/tickets';
import support from '@/mixins/support.mixin';
import notify from '@/mixins/notify.mixin';

export default {
  components: {
    TicketModal,
    SupportTicket,
    SupportTicketSidebar,
    VuePerfectScrollbar,
  },
  mixins: [ support, notify ],
  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentUser: null,
      tickets: {
        loading: true,
        items: [],
        selected: [],
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        },
        paging: {
          page: 1,
          size: 25,
          total: 0,
          options: {
            sizes: [10, 25, 50, 100]
          }
        },
        icon: 'fas fa-ticket',
      },
      selectedTicket: null,
      settings: {
        sidebar: { },
        showTicket: false,
        showLeftSidebar: false,
        currentBreakPoint: store.getters['app/currentBreakPoint'],
        modal: {
          show: this.$route.params.modal ? this.$route.params.modal.show : false,
          data: this.$route.params.modal ? this.$route.params.modal.data : null
        },
        perfectScrollbar: {
          maxScrollbarLength: 150,
        }
      },
      search: null,
      previousRoute: {
        name: null,
        params: null,
      },
    }
  },
  computed: {
    filteredTickets() {
      if(!this.search) {
        if(this.$route.query.label) {
          return this.tickets.items
              .filter(ticket => ticket.labels.includes(this.$route.query.label))
              .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        }
        return this.tickets.items.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }
      const fuse = new Fuse(this.tickets.items, {
        keys: [
          { name: 'title', weight: 1 },
          { name: 'text', weight: 0.75 },
          { name: 'labels', weight: 1 },
          { name: 'user.name.first', weight: 0.5 },
          { name: 'user.name.last', weight: 0.5 },
        ],
        threshold: 0.3,
        shouldSort: true,
      })

      if(this.$route.query.label) {
        return this.search.length
            ? fuse.search(this.search).map(({ item }) => item).filter(ticket => ticket.labels.includes(this.$route.query.label)).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            : fuse.list.filter(ticket => ticket.labels.includes(this.$route.query.label)).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }
      return this.search.length
          ? fuse.search(this.search).map(({ item }) => item).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          : fuse.list.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    },

    hasCheckedTickets() {
      return this.tickets.items.length && (this.tickets.items.length === this.tickets.selected.length)
    },

    hasCheckedTicketsIndeterminate() {
      return Boolean( this.tickets.selected.length) && this.tickets.items.length !== this.tickets.selected.length
    },

    pagination() {
      const to = this.tickets.paging.size * (this.tickets.paging.page)
      return {
        from: this.tickets.paging.size * (this.tickets.paging.page - 1) + (this.tickets.items.length > 0 ? 1 : 0),
        to: to < this.tickets.paging.total ? to : this.tickets.paging.total,
        of: this.tickets.paging.total,
      }
    },
  },
  watch: {
    '$route.name'(n, o) {
      this.previousRoute.name = o
    },
    '$route.params'(n, o) {
      this.previousRoute.params = o
      if(n?.status !== o?.status && !n.id && !o.id) {
        this.listTickets()
      }
    },
    'settings.showLeftSidebar'(val, oldVal) {
      if (oldVal === 'md' && val === 'lg') this.settings.showLeftSidebar = false
    }
  },
  async mounted() {
    this.onCreateTicket()
    this.onUpdateTicket()
    this.onDeleteTicket()
    await this.getUser()
    await this.listTickets()
    if(this.$route.params?.id) {
      const selected = this.tickets.items.find(ticket => ticket.id === this.$route.params.id)
      if(selected) {
        this.selectedTicket = selected
        this.settings.showTicket = true
      }
    }
  },
  beforeDestroy() {
    if(this.tickets?.subscriptions?.onCreate) {
      this.tickets.subscriptions.onCreate.unsubscribe()
    }
    if(this.tickets?.subscriptions?.onUpdate) {
      this.tickets.subscriptions.onUpdate.unsubscribe()
    }
    if(this.tickets?.subscriptions?.onDelete) {
      this.tickets.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    async getUser() {
      const user = await Auth.currentAuthenticatedUser()
      this.currentUser = {
        id: user.attributes['custom:user_id'],
        groups: user.signInUserSession.accessToken.payload['cognito:groups']
      }
    },
    async listTickets() {
      this.tickets.loading = true
      const params = this.$route.params || null
      /** Request **/
      if(this.isStaff) {
        await this.listTicketsStaff(params)
      }
      else {
        await this.listTicketsUser(params)
      }
    },

    async listTicketsStaff(params, nextToken, pagedTickets) {
      const tickets = pagedTickets || []
      if(params.status === 'all' || params.id) {
        const response = await API.graphql(graphqlOperation(listTickets, { limit: 500, nextToken: nextToken}));
        tickets.push(...response.data.listTickets.items);
        if(response.data.listTickets.nextToken) {
          await this.listTicketsStaff(response.data.listTickets.nextToken, tickets)
        }
        else {
          this.tickets.items = tickets
          this.$nextTick(() => setTimeout(() => { this.tickets.loading = false }, 500))
        }
      }
      else {
        const response = await API.graphql(graphqlOperation(listTicketsByStatus, { status: params.status, limit: 500, nextToken: nextToken}));
        tickets.push(...response.data.listTicketsByStatus.items);
        if(response.data.listTicketsByStatus.nextToken) {
          await this.listTicketsStaff(response.data.listTicketsByStatus.nextToken, tickets)
        }
        else {
          this.tickets.items = tickets
          this.$nextTick(() => setTimeout(() => { this.tickets.loading = false }, 500))
        }
      }
    },

    async listTicketsUser(params, nextToken, pagedTickets) {
      const tickets = pagedTickets || []
      const response = await API.graphql(graphqlOperation(listTicketsByUser, { userID: this.currentUser.id, limit: 500, nextToken: nextToken }));
      tickets.push(...response.data.listTicketsByUser.items);
      if(response.data.listTicketsByUser.nextToken) {
        await this.listTicketsUser(response.data.listTicketsByUser.nextToken, tickets)
      }
      else {
        if(params.status === 'all' || params.id) { this.tickets.items = tickets }
        else { this.tickets.items = tickets.filter(ticket => ticket.status === params.status) }
        this.$nextTick(() => setTimeout(() => { this.tickets.loading = false }, 500))
      }
    },

    onCreateTicket() {
      this.tickets.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateTicket)).subscribe((sourceData) => {
        const ticket = sourceData.value.data.onCreateTicket
        if (ticket && !this.tickets.items.map(item => item.id).includes(ticket.id)) {
          if(this.$can('manage', 'support-tickets') || ticket.userID === this.currentUser.id) {
            this.syncNotification()
            this.tickets.loading = true
            this.tickets.items = [ticket, ...this.tickets.items];
            this.$nextTick(() => { this.tickets.loading = false })
          }
        }
      });
    },
    onUpdateTicket() {
      this.tickets.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateTicket)).subscribe((sourceData) => {
        const ticket = sourceData.value.data.onUpdateTicket
        if (ticket && this.tickets.items.map(item => item.id).includes(ticket.id)) {
          if(this.$can('manage', 'support-tickets') || ticket.userID === this.currentUser.id) {
            this.syncNotification()
            this.tickets.loading = true
            const index = this.tickets.items.findIndex(item => item.id === ticket.id)
            if (index > -1) {
              this.tickets.items.splice(index, 1, ticket)
              try {
                if(ticket.id === this.selectedTicket.id) {
                  this.selectedTicket = ticket
                }
              }
              catch(e) { console.error(e) }
            }
            this.$nextTick(() => { this.tickets.loading = false })
          }
        }
      });
    },
    onDeleteTicket() {
      this.tickets.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteTicket)).subscribe((sourceData) => {
        const ticket = sourceData.value.data.onDeleteTicket
        if(ticket && this.tickets.items.map(item => item.id).includes(ticket.id)) {
          if(this.$can('manage', 'support-tickets') || ticket.userID === this.currentUser.id) {
            this.syncNotification()
            this.tickets.loading = true
            this.tickets.items = this.tickets.items.filter(item => item.id !== ticket.id);
            if(ticket.id === this.selectedTicket?.id) {
              this.deselectTicket()
            }
            this.$nextTick(() => { this.tickets.loading = false })
          }
        }
      });
    },

    selectTicket(ticket) {
      this.selectedTicket = ticket
      this.settings.showTicket = true
      this.$router.push({ name: 'support-ticket', params: { id: ticket.id }})
    },
    deselectTicket() {
      this.selectedTicket = null
      this.goToPreviousRoute()
      this.settings.showTicket = false
    },
    deleteTicketAndReplies(id) {
      this.tickets.items = this.tickets.items.filter(ticket => ticket.id !== id)
      this.deselectTicket()
    },

    checkTicket(id) {
      const index = this.tickets.selected.indexOf(id)
      if (index === -1) this.tickets.selected.push(id)
      else this.tickets.selected.splice(index, 1)
    },
    checkAllTickets(checked) {
      this.tickets.selected = checked ? this.tickets.items.map(ticket => ticket.id) : []
    },
    async updateCheckedTicketsStatus(status) {
      const ticketsForUpdate = this.tickets.selected.map(async id => {
        await API.graphql(graphqlOperation(updateTicket, { input: { id: id, status: status }}))
      })
      Promise.all(ticketsForUpdate).then(() => {
        this.notify({ title: 'Success', text: 'Tickets were successfully updated', icon: this.tickets.icon, variant: 'success' });
      }).catch(error => { console.error(error) })
    },
    async updateCheckedTicketsLabels(label) {
      const tickets = this.tickets.items.filter(t => this.tickets.selected.includes(t.id))
      const ticketsForUpdate = tickets.map(async ticket => {
        ticket.labels.includes(label) ? ticket.labels = ticket.labels.filter(fl => fl !== label) : ticket.labels.push(label)
        await API.graphql(graphqlOperation(updateTicket, { input: { id: ticket.id, labels: ticket.labels }}))
      })
      Promise.all(ticketsForUpdate).then(() => {
        this.notify({ title: 'Success', text: 'Tickets were successfully updated', icon: this.tickets.icon, variant: 'success' });
      }).catch(error => { console.error(error) })
    },
    async deleteCheckedTicketsAndReplies() {
      const deletedTickets = []
      const ticketsForDeletion = this.tickets.selected.map(async id => {
        await API.graphql(graphqlOperation(deleteTicket, {input: {id: id}})).then(response => {
          deletedTickets.push(response.data.deleteTicket)
        });
      })
      Promise.all(ticketsForDeletion).then(() => {
        const deletedReplies = []
        const repliesForDeletion = deletedTickets.map(ticket => ticket?.replies?.items.map(async reply => {
          await API.graphql(graphqlOperation(deleteTicketReply, { input: { id: reply.id } })).then(response => {
            deletedReplies.push(response.data.deleteTicketReply)
          });
        }))
        Promise.all(repliesForDeletion).then(() => {
          this.tickets.items = this.tickets.items.filter(ticket => !deletedTickets.map(dt => dt.id).includes(ticket.id))
          this.tickets.selected = []
          this.notify({ title: 'Success', text: 'Tickets and Replies were successfully deleted', icon: this.tickets.icon, variant: 'success' });
        }).catch(error => { console.error(error) })
      })
    },

    goToPreviousRoute() {
      if(this.previousRoute.name === null || !this.previousRoute.name.startsWith('support-ticket')) {
        this.$router.push({ name: 'support-tickets'})
      }
      else {
        this.$router.go(-1);
      }
    },
    formatDateToMonthShort(value, toTimeForCurrentDay = true) {
      const date = new Date(value)
      let formatting = { month: 'short', day: 'numeric' }

      if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
      }

      return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    },
    truncateTicketText(text, length = 150) {
      const cleaned = text.replace(/(<([^>]+)>)/gi, '')
      return cleaned.length >= length ? `${cleaned.slice(0, length)}...` : cleaned;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-email.scss";
 #ticket-footer {
   padding: 0.5rem;
   border-top: 1px solid $border-color;
   background-color: $white;
 }
.ticket-status {
  margin-top: .25rem
}

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
