var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.show,"title":"Create Ticket","modal-class":_vm.options.expanded ? 'modal-sticky-full' : 'modal-sticky',"footer-class":"d-flex justify-content-between","body-class":"p-0","size":"xl","no-fade":"","hide-backdrop":"","static":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('show-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v("Create Ticket")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":function($event){return _vm.$emit('show-modal', false)}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"Maximize2Icon"},on:{"click":function($event){_vm.options.expanded = !_vm.options.expanded}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.$emit('show-modal', false)}}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('validation-observer',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var failed = ref.failed;
return [_c('b-alert',{staticClass:"mb-0 square d-flex",attrs:{"show":failed,"variant":"danger"}},_vm._l((errors),function(error,key){return _c('div',{key:key},[(error.length)?_c('b-badge',{staticClass:"mr-25",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(error[0])+" ")]):_vm._e()],1)}),0),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('validation-provider',{attrs:{"vid":"title","name":"Title","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title: ")]),_c('b-form-input',{attrs:{"id":"title","state":_vm.getValidationState(validationContext)},model:{value:(_vm.ticket.title),callback:function ($$v) {_vm.$set(_vm.ticket, "title", $$v)},expression:"ticket.title"}})],1)]}}],null,true)}),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"labels"}},[_vm._v("Labels: ")]),_c('v-select',{staticClass:"v-select-inline",attrs:{"id":"labels","options":_vm.filteredLabels,"label":"title","reduce":function (label) { return label.title; },"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return [(icon.startsWith('fa'))?_c('font-awesome-icon',{staticClass:"mr-50 align-middle",attrs:{"icon":icon,"size":"lg"}}):_c('feather-icon',{staticClass:"mr-50 align-middle",attrs:{"icon":icon,"size":"18"}}),_c('span',{staticClass:"align-middle text-capitalize"},[_vm._v(_vm._s(title))])]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return [(icon.startsWith('fa'))?_c('font-awesome-icon',{staticClass:"mr-25 align-middle",attrs:{"icon":icon,"size":"lg"}}):_c('feather-icon',{staticClass:"mr-25 align-middle",attrs:{"icon":icon,"size":"15"}}),_c('span',{staticClass:"align-middle text-capitalize"},[_vm._v(_vm._s(title))])]}}],null,true),model:{value:(_vm.ticket.labels),callback:function ($$v) {_vm.$set(_vm.ticket, "labels", $$v)},expression:"ticket.labels"}})],1),_c('validation-provider',{attrs:{"vid":"text","name":"Message","rules":"required|min:50"}},[_c('tip-tap',{attrs:{"toolbar-options":{ alignment: false, table: false, image: false, schools: true },"toolbar-primary-options-class":"pr-1","placeholder":"New Ticket"},model:{value:(_vm.ticket.text),callback:function ($$v) {_vm.$set(_vm.ticket, "text", $$v)},expression:"ticket.text"}})],1)],1)]}}])})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.observer.handleSubmit(_vm.create)}}},[_vm._v(" Create ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary"},on:{"click":_vm.clear}},[_vm._v(" Clear ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }