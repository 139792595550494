<template>
  <b-card no-body :border-variant="isTicket ? 'primary' : null" :bg-variant="reply.state.hidden ? 'light-danger' : null">
    <template v-if="reply && reply.isAutomatedReply === true">
      <b-card-body class="d-flex justify-content-between bg-light-primary rounded">
        <!-- eslint-disable vue/no-v-html -->
        <div class="mail-message" v-html="reply.text"/>
        <!-- eslint-enable -->

        <div class="mail-meta-item d-flex align-items-center">
          <small class="mail-date-time text-muted">{{ formatDate(reply.createdAt) }}</small>
          <!-- Mail Action DD -->
          <feather-icon v-if="reply.state.locked" v-b-tooltip title="Locked"
                        icon="LockIcon"
                        class="ml-75"
                        size="15" />

          <feather-icon v-if="reply.state.hidden" v-b-tooltip title="Hidden"
                        icon="EyeOffIcon"
                        class="ml-75"
                        size="15" />

          <b-dropdown variant="link" no-caret toggle-class="p-0" right :disabled="!isMine && !isTicketStaff">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="ml-50 text-body"/>
            </template>

            <b-dropdown-header>Actions</b-dropdown-header>
            <b-dropdown-divider/>

            <template>
              <can v-if="(isMine || isStaff ) && !isTicketLocked" do="ticket.edit" on="support-ticket">
                <b-dropdown-item @click="state.editing = !state.editing">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.lock" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="lockTicketReply">
                  <feather-icon :icon="reply.state.locked ? 'UnlockIcon' : 'LockIcon'" />
                  <span class="align-middle ml-50">{{ reply.state.locked ? 'UnLock' : 'Lock' }}</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.hide" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="hideTicketReply">
                  <feather-icon :icon="reply.state.hidden ? 'EyeIcon' : 'EyeOffIcon'" />
                  <span class="align-middle ml-50">{{ reply.state.hidden ? 'Unhide' : 'Hide' }}</span>
                </b-dropdown-item>
              </can>
              <can v-if="!isTicket && isMine && !isTicketStaff" do="ticket.delete.comment" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="hideTicketReply">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.delete.comment" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="deleteTicketReply">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </can>
            </template>
          </b-dropdown>
        </div>
      </b-card-body>
    </template>
    <template v-else>
      <b-card-header v-if="reply" class="email-detail-head">
        <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
          <b-avatar size="48" :src="reply.user.avatar.src" variant="light-primary" class="mr-75" />
          <div class="mail-items">
            <h5 class="mb-0">
              {{ reply.user.name.first }} {{ reply.user.name.last }}
            </h5>
            <small>@{{ reply.user.username }}</small>
          </div>
        </div>
        <div class="mail-meta-item d-flex align-items-center">
          <small class="mail-date-time text-muted">{{ formatDate(reply.createdAt) }}</small>
          <!-- Mail Action DD -->
          <feather-icon v-if="reply.state.locked" v-b-tooltip title="Locked"
                        icon="LockIcon"
                        class="ml-75"
                        size="15" />

          <feather-icon v-if="reply.state.hidden" v-b-tooltip title="Hidden"
                        icon="EyeOffIcon"
                        class="ml-75"
                        size="15" />

          <b-dropdown variant="link" no-caret toggle-class="p-0" right :disabled="!isMine && !isTicketStaff">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="ml-50 text-body"/>
            </template>

            <b-dropdown-header>Actions</b-dropdown-header>
            <b-dropdown-divider/>

            <template>
              <can v-if="(isMine || isStaff ) && !isTicketLocked" do="ticket.edit" on="support-ticket">
                <b-dropdown-item @click="state.editing = !state.editing">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.lock" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="lockTicketReply">
                  <feather-icon :icon="reply.state.locked ? 'UnlockIcon' : 'LockIcon'" />
                  <span class="align-middle ml-50">{{ reply.state.locked ? 'UnLock' : 'Lock' }}</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.hide" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="hideTicketReply">
                  <feather-icon :icon="reply.state.hidden ? 'EyeIcon' : 'EyeOffIcon'" />
                  <span class="align-middle ml-50">{{ reply.state.hidden ? 'Unhide' : 'Hide' }}</span>
                </b-dropdown-item>
              </can>
              <can v-if="!isTicket && isMine && !isTicketStaff" do="ticket.delete.comment" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="hideTicketReply">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </can>
              <can do="ticket.delete.comment" on="support-ticket">
                <b-dropdown-item v-if="!isTicket" @click="deleteTicketReply">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </can>
            </template>
          </b-dropdown>
        </div>

      </b-card-header>
      <b-card-body class="mail-message-wrapper pt-2">
        <template v-if="state.editing">
          <tip-tap v-model="reply.text" />
          <div class="d-flex border-bottom-0">
            <b-button v-if="isTicket" variant="primary" size="sm" :disabled="!reply.text" @click="updateTicket">Update</b-button>
            <b-button v-else variant="primary" size="sm" :disabled="!reply.text" @click="updateTicketReply">Update</b-button>
          </div>
        </template>
        <template v-else>
          <!-- eslint-disable vue/no-v-html -->
          <div class="mail-message" v-html="reply.text"/>
          <!-- eslint-enable -->
        </template>

        <template v-if="reply.createdAt !== reply.updatedAt">
          <span class="float-right font-italic font-small-2 text-muted">Edited {{ formatDate(reply.updatedAt) }}</span>
        </template>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,
} from 'bootstrap-vue'
import {API, graphqlOperation} from 'aws-amplify';
import {deleteTicketReply, updateTicket, updateTicketReply} from './queries/ticket';
import notify from '@/mixins/notify.mixin';
import support from '@/mixins/support.mixin';
import TipTap from '@/components/TipTap.vue';

export default {
  components: {
    TipTap,
  },
  mixins: [ support, notify ],
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    reply: {
      type: Object,
      required: true,
    },
    isTicket: {
      type: Boolean,
      default: false
    },
    isTicketLocked: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      options: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Reply...',
      },
      state: {
        editing: false
      }
    }
  },
  computed: {
    isTicketStaff() {
      const allowedGroups = ['Admin', 'NYSSMA_Admin', 'NYSSMA_Staff']
      return this.currentUser.groups.some(group => allowedGroups.includes(group))
    },
    isMine() {
      return this.currentUser.id === this.reply.user.id
    },
    hasActions() {
      if(this.isTicketLocked) { return false }
      return (this.isMine || this.isTicketStaff)
          && (this.$can('ticket.edit', 'support-ticket')
              || this.$can('ticket.lock', 'support-ticket')
              || this.$can('ticket.hide', 'support-ticket')
              || this.$can('ticket.delete', 'support-ticket'))
    },
  },
  methods: {
    formatDate(value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) {
      if (!value) return value
      return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    },
    async updateTicket() {
      await API.graphql(graphqlOperation(updateTicket, { input: { id: this.reply.id, text: this.reply.text } } ));
      this.state.editing = false
    },
    async updateTicketReply() {
      await API.graphql(graphqlOperation(updateTicketReply, { input: { id: this.reply.id, text: this.reply.text } } ));
      this.state.editing = false
    },
    async lockTicketReply() {
      this.reply.state.locked = !this.reply.state.locked
      const response = await API.graphql(graphqlOperation(updateTicketReply, { input: { id: this.reply.id, state: this.reply.state } } ));
      this.state.editing = false
    },
    async hideTicketReply() {
      this.reply.state.hidden = !this.reply.state.hidden
      const response = await API.graphql(graphqlOperation(updateTicketReply, { input: { id: this.reply.id, state: this.reply.state } } ));
      this.state.editing = false
    },
    async deleteTicketReply() {
      await API.graphql(graphqlOperation(deleteTicketReply, { input: { id: this.reply.id } } ));
      this.$emit('delete-ticket-reply', this.reply.id)
    },
  }
}
</script>

<style>
[dir] .email-application .email-app-details .email-scroll-area .bg-light-danger.card  .email-detail-head {
  border-bottom: 1px solid #ea5455;
}

</style>
