export default {
    data() {
      return {
          labels: [
              {
                  title: 'account',
                  subject: 'account-settings',
                  color: 'success',
                  icon: 'UserIcon',
                  route: { name: 'support-tickets-status', query: { label: 'account' } },
              },
              {
                  title: 'application',
                  subject: 'all-state-application',
                  color: 'primary',
                  icon: 'far fa-clipboard',
                  route: { name: 'support-tickets-status', query: { label: 'application' } }
              },
              {
                  title: 'student',
                  subject: 'all-state-student',
                  color: 'warning',
                  icon: 'fas fa-graduation-cap',
                  route: { name: 'support-tickets-status', query: { label: 'student' } }
              },
              {
                  title: 'scores',
                  subject: 'all-state-scores',
                  color: 'warning',
                  icon: 'HashIcon',
                  route: { name: 'support-tickets-status', query: { label: 'scores' } }
              },
              {
                  title: 'selection',
                  subject: 'all-state-selection',
                  color: 'warning',
                  icon: 'CheckSquareIcon',
                  route: { name: 'support-tickets-status', query: { label: 'selection' } }
              },
              {
                  title: 'billing',
                  subject: 'all-state-bill',
                  color: 'warning',
                  icon: 'far fa-credit-card',
                  route: { name: 'support-tickets-status', query: { label: 'billing' } }
              },
              {
                  title: 'bug',
                  color: 'danger',
                  icon: 'fas fa-bug',
                  route: { name: 'support-tickets-status', query: { label: 'bug' } }
              },
          ],
          status: [
              {
                  title: 'all',
                  color: 'primary',
                  icon: 'MailIcon',
                  route: { name: 'support-tickets', params: { status: 'all' } },
                  isOption: false,
              },
              {
                  title: 'open',
                  color: 'success',
                  icon: 'SendIcon',
                  route: { name: 'support-tickets-status', params: { status: 'open' } },
                  isOption: true,
              },
              {
                  title: 'in-progress',
                  color: 'warning',
                  icon: 'SendIcon',
                  route: { name: 'support-tickets-status', params: { status: 'in-progress' } },
                  isOption: true,
              },
              {
                  title: 'closed',
                  color: 'danger',
                  icon: 'Edit2Icon',
                  route: { name: 'support-tickets-status', params: { status: 'closed' } },
                  isOption: true,
              },

          ]
      }
    },
    computed: {
        isStaff() {
            const allowedGroups = ['Admin', 'NYSSMA_Admin', 'NYSSMA_Staff']
            return this.currentUser.groups.some(group => allowedGroups.includes(group))
        },
        filteredLabels() {
            return this.labels.filter(label => !label.subject || this.$can('read', label.subject))
        },
        filteredStatuses() {
            return this.status.filter(s => s.isOption)
        }
    },
    methods: {
        getLabelColor(label) {
            return this.labels.find(l => l.title?.toLowerCase() === label?.toLowerCase())?.color || 'dark'
        },
        getLabelIcon(label) {
            return this.labels.find(l => l.title?.toLowerCase() === label?.toLowerCase())?.icon || 'fas fa-user-secret'
        },
        getStatusColor(status) {
            return this.status.find(s => s.title?.toLowerCase() === status?.toLowerCase())?.color || 'dark'
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
    }
}
