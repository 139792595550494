// eslint-disable-next-line import/prefer-default-export
export const getTicket = /* GraphQL */ `
    query GetTicket($id: ID!) {
        getTicket(id: $id) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateTicket = /* GraphQL */ `
    mutation UpdateTicket(
        $input: UpdateTicketInput!
        $condition: ModelTicketConditionInput
    ) {
        updateTicket(input: $input, condition: $condition) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteTicket = /* GraphQL */ `
    mutation DeleteTicket(
        $input: DeleteTicketInput!
        $condition: ModelTicketConditionInput
    ) {
        deleteTicket(input: $input, condition: $condition) {
            id
            title
            text
            status
            labels
            userID
            user {
                id
                username
                name {
                    first
                    last
                    preferred
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            createdAt
            updatedAt
        }
    }
`;

export const listTicketReplies = /* GraphQL */ `
    query ListTicketReplies(
        $id: ID
        $filter: ModelTicketReplyFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listTicketReplies(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                text
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        name
                        size
                        src
                    }
                }
                state {
                    locked
                    hidden
                }
                isAutomatedReply
                createdAt
                updatedAt
                ticketRepliesId
                ticketReplyUserId
            }
            nextToken
        }
    }
`;
export const createTicketReply = /* GraphQL */ `
    mutation CreateTicketReply(
        $input: CreateTicketReplyInput!
        $condition: ModelTicketReplyConditionInput
    ) {
        createTicketReply(input: $input, condition: $condition) {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;
export const updateTicketReply = /* GraphQL */ `
    mutation UpdateTicketReply(
        $input: UpdateTicketReplyInput!
        $condition: ModelTicketReplyConditionInput
    ) {
        updateTicketReply(input: $input, condition: $condition) {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;
export const deleteTicketReply = /* GraphQL */ `
    mutation DeleteTicketReply(
        $input: DeleteTicketReplyInput!
        $condition: ModelTicketReplyConditionInput
    ) {
        deleteTicketReply(input: $input, condition: $condition) {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;

export const onCreateTicketReply = /* GraphQL */ `
    subscription OnCreateTicketReply {
        onCreateTicketReply {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;
export const onUpdateTicketReply = /* GraphQL */ `
    subscription OnUpdateTicketReply {
        onUpdateTicketReply {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;
export const onDeleteTicketReply = /* GraphQL */ `
    subscription OnDeleteTicketReply {
        onDeleteTicketReply {
            id
            text
            user {
                id
                username
                name {
                    first
                    last
                }
                avatar {
                    size
                    name
                    src
                }
            }
            state {
                locked
                hidden
            }
            isAutomatedReply
            createdAt
            updatedAt
            ticketRepliesId
            ticketReplyUserId
        }
    }
`;


