<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('deselect')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ ticket.title }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <!-- Show Hidden Replies -->
        <can do="ticket.hide" on="support-ticket">
          <feather-icon v-if="hasHiddenReplies"
                        v-b-tooltip :title="replies.showHidden ? 'Hide Hidden Replies' : 'Show Hidden Replies'"
                        :icon="replies.showHidden ? 'EyeOffIcon' : 'EyeIcon'"
                        class="ml-75"
                        size="17" @click="replies.showHidden = !replies.showHidden"/>
        </can>


        <!-- Update Label -->
        <can do="ticket.label" on="support-ticket">
          <b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-75" right>
            <template #button-content>
              <feather-icon v-b-tooltip title="Update Ticket Labels"
                            icon="TagIcon"
                            size="17"
                            class="align-middle text-body"/>
            </template>
            <b-dropdown-item v-for="(label) in filteredLabels" :key="label.title" :active="hasLabel(label)" @click="updateTicketLabels(label)" >
              <font-awesome-icon v-if="label.icon.startsWith('fa')" :icon="label.icon" :class="['mr-75']" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
              <feather-icon v-else :icon="label.icon" size="18" class="mr-75"/>
              <span class="text-capitalize">{{ label.title }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </can>


        <!-- Update Status -->
        <can do="ticket.status" on="support-ticket">
          <b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-75" right>
            <template #button-content>
              <feather-icon v-b-tooltip title="Update Ticket Status"
                            icon="FlagIcon"
                            size="17"
                            class="align-middle text-body"/>
            </template>
            <b-dropdown-item v-for="(status) in filteredStatuses" :key="status.title" :disabled="ticket.status === status.title" @click="updateTicketStatus(status)" >

              <span :class="`mr-50 bullet bullet-${status.color} bullet-sm`" />
              <span class="text-capitalize">{{ status.title }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </can>

        <!-- Lock Ticket -->
        <can do="ticket.lock" on="support-ticket">
          <feather-icon v-b-tooltip :title="ticket.state.locked ? 'Unlock Ticket' : 'Lock Ticket'"
                        :icon="ticket.state.locked ? 'UnlockIcon' : 'LockIcon'"
                        class="ml-75"
                        size="17" @click="updateTicketState"/>
        </can>

        <!-- Delete Ticket & Replies -->
        <can do="ticket.delete" on="support-ticket">
          <feather-icon v-b-tooltip title="Delete Ticket"
                        icon="TrashIcon" class="ml-75" size="17" @click="deleteTicketAndReplies"/>
        </can>
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="email-scroll-area scroll-area">

      <!-- Status | Labels | state -->
      <b-row>
        <b-col cols="12">
          <div class="email-label align-middle">
            <small>Status:</small> <b-badge :variant="getStatusColor(ticket.status)" pill class="text-capitalize mr-50">{{ ticket.status }}</b-badge>
            <template v-if="ticket.state.locked">
              <b-badge variant="dark" pill class="text-capitalize mr-50">
                <feather-icon icon="LockIcon" size="18" class="mr-25 align-middle"/>
                <span class="align-middle">locked</span>
              </b-badge>
            </template>
            <template v-if="ticket.labels.length">
              <small>Labels: </small>
              <b-badge v-for="(label) in ticket.labels" :key="label" pill class="text-capitalize align-middle mr-50" :variant="`light-${getLabelColor(label)}`">
                <font-awesome-icon v-if="getLabelIcon(label).startsWith('fa')" :icon="getLabelIcon(label)" class="mr-25 align-middle" size="lg"></font-awesome-icon> <!--`text-${label.color}`-->
                <feather-icon v-else :icon="getLabelIcon(label)" size="18" class="mr-25 align-middle"/>
                <span class="align-middle">{{ label }}</span>
              </b-badge>
            </template>
          </div>
        </b-col>
      </b-row>


      <!-- Ticket -->
      <template>
        <b-row>
          <b-col cols="12">
            <support-ticket-card :reply="ticket"
                                 :current-user="currentUser"
                                 is-ticket
                                 :is-ticket-locked="ticket.state.locked" />
          </b-col>
        </b-row>
      </template>

      <!-- New Reply -->
      <template v-if="ticket.state.locked !== true">
        <hr>
        <b-row>
          <b-col cols="12">
            <b-card>
              <tip-tap v-model="reply.text"
                       placeholder="Reply..."
                       classes="editor-xs"></tip-tap>
              <div class="d-flex border-bottom-0">
                <b-button variant="primary" size="sm" :disabled="!reply.text" @click="createTicketReply">Submit</b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </template>

      <!-- Ticket Replies -->
      <template>
        <b-overlay :show="replies.loading" :opacity="1" variant="white">
          <b-tabs>
            <b-tab title="Replies">
              <template v-if="sortedReplies.filter(r => r.isAutomatedReply !== true).length">
                <b-row v-for="reply in sortedReplies.filter(r => r.isAutomatedReply !== true)" :key="reply.id">
                  <b-col cols="12">
                    <support-ticket-card v-if="replies.showHidden || reply.state.hidden !== true"
                                         :reply="reply"
                                         :current-user="currentUser"
                                         :is-ticket-locked="ticket.state.locked"
                                         @delete-ticket-reply="deleteTicketReply"/>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-alert show variant="light">No Replies</b-alert>
              </template>
            </b-tab>
            <b-tab title="All" lazy>
              <template v-if="sortedReplies.length">
                <b-row v-for="reply in sortedReplies" :key="reply.id">
                  <b-col cols="12">
                    <support-ticket-card v-if="replies.showHidden || reply.state.hidden !== true"
                                         :reply="reply"
                                         :current-user="currentUser"
                                         :is-ticket-locked="ticket.state.locked"
                                         @delete-ticket-reply="deleteTicketReply"/>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-alert show variant="light">No Replies or System Messages</b-alert>
              </template>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </template>


      <template v-if="false">
        <debug>
          {{ ticket }}
        </debug>

        <debug>
          {{ replies }}
        </debug>
      </template>



    </vue-perfect-scrollbar>
  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import useEmail from './useEmail'
import SupportTicketCard from '@/views/support/tickets/SupportTicketCard.vue';
import {API, graphqlOperation} from 'aws-amplify';
import {
  listTicketReplies, createTicketReply, deleteTicketReply,
  updateTicket, deleteTicket,
  onCreateTicketReply, onUpdateTicketReply, onDeleteTicketReply
} from './queries/ticket';
import {uuid} from 'vue-uuid';
import support from '@/mixins/support.mixin';
import notify from '@/mixins/notify.mixin';
import vSelect from 'vue-select';
import TipTap from '@/components/TipTap.vue';


export default {
  components: {
    TipTap,
    SupportTicketCard,
    VuePerfectScrollbar,
    vSelect,
  },
  mixins: [ support, notify ],
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reply: {
        text: '',
        options: {
          modules: {
            toolbar: '#quill-toolbar',
          },
          placeholder: 'Reply...',
        }
      },
      replies: {
        loading: true,
        loaded: 0,
        items: [],
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        },
        showHidden: false
      },
      icon: 'fas fa-ticket',
      prevRoute: null,
    }
  },
  computed: {
    sortedReplies() {
      return this.replies.loading ? [] : this.replies.items.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    },
    hasHiddenReplies() {
      return this.replies.items.some(reply => reply.state.hidden === true)
    },
  },
  watch: {
    async ticket() {
      await this.listTicketReplies()
    }
  },
  async mounted() {
    await this.listTicketReplies()
    this.onCreateTicketReply()
    this.onUpdateTicketReply()
    this.onDeleteTicketReply()
  },
  beforeDestroy() {
    if(this.replies?.subscriptions?.onCreate) {
      this.replies.subscriptions.onCreate.unsubscribe()
    }
    if(this.replies?.subscriptions?.onUpdate) {
      this.replies.subscriptions.onUpdate.unsubscribe()
    }
    if(this.replies?.subscriptions?.onDelete) {
      this.replies.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    onCreateTicketReply() {
      this.replies.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateTicketReply)).subscribe((sourceData) => {
        const reply = sourceData.value.data.onCreateTicketReply
        if (reply && (reply.ticketRepliesId === this.ticket.id) && !this.replies.items.map(item => item.id).includes(reply.id)) {
          this.syncNotification()
          this.replies.loading = true
          this.replies.items = [reply, ...this.replies.items];
          this.replies.loading = false
        }
      });
    },
    onUpdateTicketReply() {
      this.replies.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateTicketReply)).subscribe((sourceData) => {
        const reply = sourceData.value.data.onUpdateTicketReply
        if (reply && (reply.ticketRepliesId === this.ticket.id) && this.replies.items.map(item => item.id).includes(reply.id)) {
          this.syncNotification()
          this.replies.loading = true
          const index = this.replies.items.findIndex(item => item.id === reply.id)
          if(index > -1) {
            this.replies.items.splice(index, 1, reply)
          }
          this.replies.loading = false
        }
      });
    },
    onDeleteTicketReply() {
      this.replies.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteTicketReply)).subscribe((sourceData) => {
        const reply = sourceData.value.data.onDeleteTicketReply
        if(reply && (reply.ticketRepliesId === this.ticket.id) && this.replies.items.map(item => item.id).includes(reply.id)) {
          this.syncNotification()
          this.replies.loading = true
          this.replies.items = this.replies.items.filter(item => item.id !== reply.id);
          this.replies.loading = false
        }
      });
    },

    async updateTicketLabels(label) {
      if(this.ticket.labels.includes(label.title)) {
        this.ticket.labels = this.ticket.labels.filter(l => l !== label.title)
      }
      else { this.ticket.labels.push(label.title) }
      this.ticket.labels = [...new Set(this.ticket.labels)]

      await API.graphql(graphqlOperation(updateTicket, { input: { id: this.ticket.id, labels: this.ticket.labels} }));
      await this.notify({ title: 'Success', text: 'Ticket was successfully updated', icon: this.icon, variant: 'success' });
    },
    async updateTicketStatus(status) {
      this.ticket.status = status.title
      await API.graphql(graphqlOperation(updateTicket, { input: { id: this.ticket.id, status: this.ticket.status} }));
      await this.notify({ title: 'Success', text: 'Ticket was successfully updated', icon: this.icon, variant: 'success' });
      await this.createTicketReply({
        text: `<span class="font-italic">Status changed to <strong>${this.ticket.status}</strong>.</span>`,
        state: {
          locked: true
        },
        isAutomatedReply: true,
        notify: false
      })
    },
    async updateTicketState() {
      this.ticket.state.locked = !this.ticket.state.locked
      await API.graphql(graphqlOperation(updateTicket, { input: { id: this.ticket.id, state: this.ticket.state} }));
      await this.notify({ title: 'Success', text: 'Ticket was successfully updated', icon: this.icon, variant: 'success' });

      const text = this.ticket.state.locked
          ? '<span class="font-italic">Ticket has been <strong>locked</strong>.</span>'
          : '<span class="font-italic">Ticket has been <strong>unlocked</strong>.</span>'

      await this.createTicketReply({
        text: text,
        state: {
          locked: true
        },
        isAutomatedReply: true,
        notify: false
      })
    },


    async createTicketReply(options = { text: null, state: { locked: false, hidden: false }, isAutomatedReply: false, notify: true }) {
      try {
        const id = uuid.v4();
        const input = {
          id: id,
          text: options?.text || this.reply.text,
          ticketRepliesId: this.ticket.id,
          ticketReplyUserId: this.currentUser.id,
          state: {
            locked: options?.state?.locked || false,
            hidden: options?.state?.hidden || false
          },
          isAutomatedReply: options?.isAutomatedReply || false
        }

        const response = await API.graphql(graphqlOperation(createTicketReply, { input: input }));
        const ticketReply = response.data.createTicketReply
        this.replies.items.push(ticketReply)
        this.reply.text = ''
        if(options.notify) {
          await this.notify({ title: 'Success', text: 'Ticket Reply was successfully created', icon: 'fas fa-server', variant: 'success' });
        }
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Ticket Reply failed to create', icon: 'fas fa-server', variant: 'danger'});
      }
    },
    async listTicketReplies(nextToken, pagedReplies) {
      const replies = pagedReplies || []
      const response = await API.graphql(graphqlOperation(listTicketReplies, { filter: { ticketRepliesId: { eq: this.ticket.id } }, limit: 500, nextToken: nextToken }));
      replies.push(...response.data.listTicketReplies.items);

      this.replies.loaded = replies.length

      if(response.data.listTicketReplies.nextToken) {
        await this.listTicketReplies(response.data.listTicketReplies.nextToken, replies)
      }
      else {
        this.replies.items = replies
        this.replies.loading = false
      }
    },
    async deleteTicketAndReplies() {
      await API.graphql(graphqlOperation(deleteTicket, { input: { id: this.ticket.id }} )).then(() => {
        this.$emit('delete-ticket-and-replies', this.ticket.id)
        this.notify({ title: 'Success', text: 'Ticket was successfully deleted', icon: this.icon, variant: 'success' });
      })
      .catch(error => { console.error(error) });

      const repliesForDeletion = this.replies.items.map(async reply => {
        await API.graphql(graphqlOperation(deleteTicketReply, { input: { id: reply.id } }));
      })
      Promise.all(repliesForDeletion).then(() => {
        this.notify({ title: 'Success', text: 'Replies were successfully deleted', icon: this.icon, variant: 'success' });
      }).catch(error => { console.error(error) })
    },
    deleteTicketReply(id) {
      this.replies.items = this.replies.items.filter(item => item.id !== id);
    },
    hasLabel(label) {
      return this.ticket.labels.includes(label.title)
    },
  },
  setup(props) {
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const showWholeThread = ref(false)

    watch(() => props.ticket.id, () => {
      showWholeThread.value = false
    })

    return {

      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // useEmail
      resolveLabelColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
  .ql-snow {
    border: 0!important;
  }
</style>
